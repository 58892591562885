<template>
  <div class="Account">
    <div class="titles">企业账户</div>
    <div class="type1">
      <div class="item">
        <img
          src="@/assets/images/AccountSettings/money1.png"
          style="height: 60px; opacity: 0.3"
          alt=""
        />
        <div class="cont">
          <div class="tit">可用余额（元）</div>
          <div class="num yell">
            {{ data.availableBalance | formatMoneyString }}
          </div>
        </div>
        <el-button
          class="butt"
          type="text"
          @click="dialogWithdrawalOpen"
          v-if="data.availableBalance > 0"
          >提现</el-button
        >
      </div>
      <div class="item item2">
        <img
          src="@/assets/images/AccountSettings/money2.png"
          style="height: 60px; opacity: 0.3"
          alt=""
        />
        <div class="cont">
          <div class="tit">账户余额（元）</div>
          <div class="num">{{ data.balance | formatMoneyString }}</div>
        </div>
      </div>
      <div class="item">
        <img
          src="@/assets/images/AccountSettings/money3.png"
          style="height: 60px; opacity: 0.3"
          alt=""
        />
        <div class="cont">
          <div class="tit">冻结金额（元）</div>
          <div class="num">{{ data.frozenAmount | formatMoneyString }}</div>
        </div>
        <el-button class="butt" type="text" @click="openDetail">明细</el-button>
      </div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first">
        <span slot="label">
          <SvgIcon iconClass="jyjl" />
          交易记录</span
        >
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            background: '#F5F5F5',
            fontWeight: 600,
            fontSize: '14px',
            color: '#000000d9',
          }"
        >
          <el-table-column prop="createTime" label="操作时间" width="180" />
          <el-table-column prop="businessType_cn" label="操作类型" />
          <el-table-column prop="amount" label="金额变动（元）" />
          <el-table-column prop="balance" label="余额（元）" />
          <el-table-column prop="userName" label="操作人" />
          <el-table-column prop="transactionId" label="交易单号" />
          <el-table-column prop="status_cn" label="状态" />
        </el-table>
        <div class="pagina">
          <el-pagination
            @current-change="getFirstList"
            :current-page.sync="padeObj1.pageNum"
            :page-size="5"
            layout="total, prev, pager, next"
            :total="padeObj1.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second">
        <span slot="label">
          <SvgIcon iconClass="zfsz" />
          支付设置</span
        >
        <div class="shezhi">
          <div class="item">
            <div>支付密码设置：</div>
            <div class="cen">静态密码-已设置</div>
            <el-button type="text" @click="dialogPass = true">修改</el-button>
          </div>
          <div class="item">
            <div>账户消息提醒：</div>
            <div class="cen">{{ PersonObj.msg || "未设置" }}</div>
            <el-button type="text" @click="openMSG">修改</el-button>
          </div>
          <div class="item">
            <div>支付权限管理：</div>
            <div class="cen">{{ PersonObj.pay || "未设置" }}</div>
            <el-button type="text" @click="openPay">修改</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="third">
        <span slot="label">
          <SvgIcon iconClass="zhcz" />
          账户充值</span
        >
        <div class="Recharge">
          <div class="left">
            <div class="titNames">汇款流程</div>
            <div class="steps">
              <div class="step">
                <div class="step-marker">1</div>
                <div class="step-content">
                  <div class="step-title">
                    企业账户充值，仅限使用账户已绑定的企业对公账户向指定账户打款。
                  </div>
                </div>
                <div class="step-connector"></div>
              </div>
              <div class="step">
                <div class="step-marker">2</div>
                <div class="step-content">
                  <div class="step-title">
                    打款请向以下账户进行打款，并保留打款凭证（截图、拍照）。
                  </div>
                  <div class="step-description">
                    <div>
                      账户名称：
                      <span class="yell">北京佰特豪斯科技有限公司</span>
                    </div>

                    <div>
                      开户行：<span class="yell">招商银行北京经济技术开发区科技金融支行</span>
                    </div>

                    <div>
                      银行账号：<span class="yell">110962500610000</span>
                    </div>

                    <div>打款备注：<span class="yell">拍卖平台充值</span></div>
                  </div>
                </div>
                <div class="step-connector"></div>
              </div>
              <div class="step">
                <div class="step-marker">3</div>
                <div class="step-content">
                  <div class="step-title">上传凭证图片。</div>
                </div>
                <div class="step-connector"></div>
              </div>
              <div class="step">
                <div class="step-marker">4</div>
                <div class="step-content">
                  <div class="step-title">
                    在下方录入打款金额，且须与实际打款金额一致。
                  </div>
                </div>
                <div class="step-connector"></div>
              </div>
              <div class="step">
                <div class="step-marker">5</div>
                <div class="step-content">
                  <div class="step-title">
                    点击提交，等待系统审核成功，即完成账户充值。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="titNames">充值金额</div>
            <el-input v-model="RechargeForm.rechargeAmount">
              <template slot="prepend">¥</template>
            </el-input>
            <div class="titNames">打款凭证</div>
            <el-upload
              ref="upload"
              v-model="RechargeForm.remittanceVoucher"
              :headers="headers"
              :limit="1"
              accept=".png,.jpg, .jpeg"
              :action="baseUrl + '/file/uploadFile'"
              multiple
              :data="RechargeForm"
              :file-list="RechargeForm.remittanceVoucher"
              :on-remove="handlePictureRemove"
              :on-exceed="handlePictureExceed"
              :before-upload="beforeUploadFile"
              list-type="picture-card"
            >
              <em class="el-icon-plus"></em>
            </el-upload>
            <el-button
              type="success"
              style="margin-top: 40px"
              @click="submitRecharge"
              >提交充值信息</el-button
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="" name="fourth">
        <span slot="label">
          <SvgIcon iconClass="kqgl" />
          卡券管理</span
        >
        <div class="coupons">
          <div class="right">
            <div class="tit">绑定银行卡</div>
            <div class="card">
              <div class="name">{{ userInfo.manu.corporateAccountBank }}</div>
              <div class="name">
                {{ userInfo.manu.corporateAccountBranchNumber }}
              </div>
            </div>
            <el-button type="text" @click="dialogCardOpen"
              >修改绑定银行卡</el-button
            >
          </div>

          <div class="left">
            <div class="tit">我的发票</div>
            <div class="invoiceList">
              <div
                class="invoiceItem"
                v-for="(item, index) in invoiceList"
                :key="index"
              >
                <span class="names">{{ item.companyName }}</span>
                <span>￥{{ item.amount }}</span>
                <span>{{ item.expressTime }}</span>
                <el-button
                  type="text"
                  @click="kuaiDi(item.expressName, item.expressNumber)"
                  >快递邮寄</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="支付密码变更" :visible.sync="dialogPass" width="460px">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="原支付密码" prop="oldPassword">
          <el-input type="password" v-model="ruleForm.oldPassword"> </el-input>
        </el-form-item>
        <el-form-item label="新支付密码" prop="newPassword">
          <el-input
            type="password"
            v-model="ruleForm.newPassword"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="smsCode">
          <div class="flexBox">
            <el-input
              v-model="ruleForm.smsCode"
              style="width: 170px; margin-right: 20px"
            >
            </el-input>
            <el-button
              type="text"
              :disabled="countdown > 0"
              @click.prevent="getYzm"
            >
              {{ countdown > 0 ? `重新获取(${countdown}s)` : "点击获取验证码" }}
            </el-button>
          </div>
          <span style="font-size:12px;color:rgba(0,0,0,0.4);"
            >系统将发送验证码到企业管理员手机号码</span
          >
        </el-form-item>
        <el-form-item style="display: flex; justify-content: end">
          <el-button type="primary" @click.prevent="submitPass('ruleForm')"
            >确认更改</el-button
          >
          <el-button @click.prevent="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="账户消息提醒"
      :visible.sync="dialogMSG"
      append-to-body
      width="460px"
      destroy-on-close
    >
      <el-form
        :model="msgForm"
        :rules="rules"
        ref="msgForm"
        label-width="100px"
        class="demo-msgForm"
      >
        <!-- <el-checkbox
          style="width: 70%; margin-bottom: 20px"
          v-model="msgForm.msgRemFixed"
          >消息提醒固定管理人员</el-checkbox
        > -->
        <el-form-item label="提醒人员" prop="msg">
          <el-select
            v-model="msgForm.msg"
            multiple
            placeholder="请选择消息提醒人员"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in thisMemberList"
              :key="item.value"
            >
              <span style="float: left">{{ item.label }}</span
              >————
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.userName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="display: flex; justify-content: end">
          <el-button type="primary" @click.prevent="submitMSG('msgForm')"
            >确认更改</el-button
          >
          <el-button @click.prevent="resetForm('msgForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="支付权限"
      :visible.sync="dialogPay"
      width="460px"
      destroy-on-close
      append-to-body
    >
      <el-form
        :model="payForm"
        :rules="rules"
        ref="payForm"
        label-width="100px"
        class="demo-payForm"
      >
        <el-form-item label="支付人员" prop="pay">
          <el-select
            v-model="payForm.pay"
            multiple
            placeholder="请选择支付人员"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in thisMemberList"
              :key="item.value"
            >
              <span style="float: left">{{ item.label }}</span
              >————
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.userName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="display: flex; justify-content: end">
          <el-button type="primary" @click.prevent="submitPay('payForm')"
            >确认更改</el-button
          >
          <el-button @click.prevent="resetForm('payForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改绑定银行卡" :visible.sync="dialogCard" width="460px">
      <el-form
        :model="cardForm"
        status-icon
        :rules="rules"
        ref="cardForm"
        label-width="100px"
        class="demo-cardForm"
      >
        <el-form-item label="企业名称">
          {{ cardForm.manuName }}
        </el-form-item>
        <el-form-item label="开户银行" prop="corporateAccountBank">
          <el-input v-model="cardForm.corporateAccountBank"></el-input>
        </el-form-item>
        <el-form-item label="开户行行号" prop="corporateAccountBranchNumber">
          <el-input v-model="cardForm.corporateAccountBranchNumber"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="corporateAccountNumber">
          <el-input v-model="cardForm.corporateAccountNumber"></el-input>
        </el-form-item>
        <el-form-item style="display: flex; justify-content: end">
          <el-button type="primary" @click.prevent="submitCard('cardForm')"
            >确认更改</el-button
          >
          <el-button @click.prevent="resetForm('cardForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="冻结金额明细" :visible.sync="dialogDetail" width="640px">
      <el-table
        :data="freezeList"
        style="width: 100%"
        :header-cell-style="{
          background: '#F5F5F5',
          fontWeight: 600,
          fontSize: '14px',
          color: '#000000d9',
        }"
      >
        <el-table-column prop="paymentTime" label="操作时间" width="180" />
        <el-table-column prop="amount" label="金额变动（元）" />
        <el-table-column prop="transactionId" label="交易单号" />
        <el-table-column prop="auctionCode" label="项目编号" />
      </el-table>
      <div class="pagina">
        <el-pagination
          @current-change="openDetail"
          :current-page.sync="freezeObj.pageNum"
          :page-size="5"
          layout="total, prev, pager, next"
          :total="freezeObj.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog
      title="钱包提现"
      :visible.sync="dialogWithdrawal"
      destroy-on-close
      width="460px"
    >
      <div class="Withdrawal">
        <div class="tit">提现金额</div>
        <div class="top">
          <span class="rmb">￥</span>
          <el-input-number
            style="width: 90%"
            autofocus
            :precision="2"
            v-model="withdrawalForm.withdrawalAmount"
            controls-position="right"
            :min="1"
            :max="withdrawalForm.availableBalance"
          ></el-input-number>
          <div>
            <span style="margin-right: 30px"
              >可提现金额 {{ withdrawalForm.availableBalance }} 元</span
            >
            <el-button
              class="butt"
              type="text"
              @click="
                withdrawalForm.withdrawalAmount =
                  withdrawalForm.availableBalance
              "
              >全部提现</el-button
            >
          </div>
        </div>
        <div class="tit">提现账户</div>
        <div class="center">
          <div class="account">
            <div class="accItem">
              <span class="label">收款单位</span>
              <span>{{ withdrawalForm.name }}</span>
            </div>
            <div class="accItem">
              <span class="label">开户行名称</span>
              <span>{{ withdrawalForm.corporateAccountBank }}</span>
            </div>
            <div class="accItem">
              <span class="label">开户行行号</span>
              <span>{{ withdrawalForm.corporateAccountBranchNumber }}</span>
            </div>
            <div class="accItem">
              <span class="label">银行卡号</span>
              <span>{{ withdrawalForm.corporateAccountNumber }}</span>
            </div>
          </div>
        </div>
        <div class="tit">支付密码</div>
        <div class="bottom">
          <PasswordInput
            ref="passwordInputRef"
            v-model="withdrawalForm.paymentPassword"
            has-gap
            @complete="onPasswordInputComplete"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { useDict } from "@/utils/dict";
import { getToken } from "@/utils/token";
import { mapState } from "vuex";
import { getSmsCode } from "@/api/index.js";
import {
  recharge,
  transactionList,
  depositList,
  balance,
  withdrawal,
  withdrawalApplication,
  updatePaymentPwd,
  memberList,
  getUserPermissions,
  addUserPermissions,
  reviseWalletInfo,
  Invoices,
} from "@/api/account";
import { deleteFile } from "@/api/workSpace";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
import PasswordInput from "./components/PasswordInput.vue";
export default {
  name: "Account",
  components: {
    PasswordInput,
  },
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      data: {},
      dict: {},
      RechargeForm: {},
      withdrawalForm: {
        withdrawalAmount: "",
      },
      baseUrl: "/api",
      headers: { Authorization: "Bearer " + getToken() },
      countdown: 0,
      activeName: "first",
      tableData: [],
      freezeList: [],
      freezeObj: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      thisMemberList: [],
      padeObj1: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      dialogPass: false,
      dialogMSG: false,
      dialogPay: false,
      dialogCard: false,
      dialogWithdrawal: false,
      dialogDetail: false,
      ruleForm: {},
      msgForm: {
        // msgRemRelevant: true,
        // msgRemFixed: true,
        msg: [],
      },
      payForm: {
        pay: [],
      },
      cardForm: {},
      PersonObj: {
        msg: "",
        pay: "",
        msg_id: [],
        pay_id: [],
      },
      card: {
        name: "农业银行",
        num: "**** **** **** 3217",
      },
      invoiceList: [],

      rules: {
        oldPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 6, message: "长度为6个字符", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            pattern: /^\d{6}$/,
            message: "请输入6位数字密码",
            trigger: "change",
          },
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.dict = useDict("wallet_oper_type");
  },
  mounted() {
    //获取我的订单数据
    // 这三个是页面加载的时候就要刷的数据
    this.getFirstList();
    this.getBlance();
    this.getMemberList();
    // 这两个是可以优化到标签跳转的，但区别不大就懒得弄了
    this.getPerson();
    this.getInvoices();
  },

  methods: {
    // 发票快递展示
    kuaiDi(expressCompany, expressNumber) {
      this.$alert(
        expressCompany + "————" + expressNumber,
        "您的发票寄送快递及快递号如下：",
        {
          confirmButtonText: "确定",
        }
      );
    },
    // 打开修改绑定卡的框
    dialogCardOpen() {
      this.dialogCard = true;
      let {
        corporateAccountBank,
        corporateAccountBranchNumber,
        corporateAccountNumber,
      } = this.userInfo.manu;
      this.cardForm = {
        corporateAccountBank,
        corporateAccountBranchNumber,
        corporateAccountNumber,
      };
      this.cardForm.manuName = this.userInfo.manuName;
    },
    submitCard(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          reviseWalletInfo(this.cardForm).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.dialogCard = false;
              this.$store.dispatch("getUserInfo");
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    // 查发票
    getInvoices() {
      Invoices(this.userInfo.manuId).then(({ data }) => {
        this.invoiceList = data;
      });
    },
    openDetail() {
      this.dialogDetail = true;
      depositList(this.freezeObj).then(({ code, rows, total }) => {
        if (code == 200) {
          this.freezeList = rows;
          this.freezeObj.total = total;
        }
      });
    },
    getPerson() {
      getUserPermissions({
        checkPerms: "client:wallet:message",
      }).then(({ code, data }) => {
        if (code == 200) {
          this.PersonObj.msg = data.UsersPerms.map(
            (i) => i.realName
          ).toString();
          this.PersonObj.msg_id = data.UsersPerms.map((i) => i.userId);
        }
      });
      getUserPermissions({
        checkPerms: "client:wallet:payment",
      }).then(({ code, data }) => {
        if (code == 200) {
          this.PersonObj.pay = data.UsersPerms.map(
            (i) => i.realName
          ).toString();
          this.PersonObj.pay_id = data.UsersPerms.map((i) => i.userId);
        }
      });
    },
    //打开账户消息提醒弹框
    openMSG() {
      this.dialogMSG = true;
      this.msgForm.msg = this.PersonObj.msg_id;
    },
    //打开账户消息提醒弹框
    openPay() {
      this.dialogPay = true;
      this.payForm.pay = this.PersonObj.pay_id;
    },
    all() {
      this.withdrawalForm.withdrawalAmount = this.withdrawalForm.availableBalance;
    },
    // 打开提现弹框
    dialogWithdrawalOpen() {
      this.dialogWithdrawal = true;
      withdrawal().then(({ code, data }) => {
        if (code == 200) this.withdrawalForm = data;
      });
    },

    // 密码输入完成回调
    onPasswordInputComplete(val) {
      let paymentPassword = encrypt(val);
      let param = {
        currentBalance: this.data.availableBalance,
        withdrawalAmount: this.withdrawalForm.withdrawalAmount,
        paymentPassword,
      };
      withdrawalApplication(param).then(({ code, msg, data }) => {
        if (code == 200) {
          this.dialogWithdrawal = false;
          this.getBlance();
          this.$message.success(msg);
        } else {
          this.$message.error(msg);
        }
      });
    },
    //删除图片
    handlePictureRemove(file, fileList) {
      deleteFile({
        objectName: "/apply/application" + file.name,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },

    // 图片限制
    handlePictureExceed() {
      this.$message({
        message: "仅能上传一张图片！",
        type: "warning",
      });
    },
    // 上传文件之前的函数
    beforeUploadFile(file) {
      this.RechargeForm.objectName = "/wallet/remittanceVoucher/" + file.name;
    },

    submitUpload() {
      this.$refs.upload.submit();
    },
    submitPay(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let usersPerms = this.payForm.pay.map((i) => {
            return {
              userId: i,
              checkPerms: "client:wallet:payment",
            };
          });
          let param = {
            usersPerms,
          };
          addUserPermissions(param).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.dialogPay = false;
              this.getPerson();
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    submitMSG(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let usersPerms = this.msgForm.msg.map((i) => {
            return {
              userId: i,
              checkPerms: "client:wallet:message",
            };
          });
          let param = {
            usersPerms,
            msgRemRelevantUserName: this.userInfo.userName,
          };
          addUserPermissions(param).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.dialogMSG = false;
              this.getPerson();
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    submitRecharge() {
      if (!this.RechargeForm.rechargeAmount) {
        return this.$message.error("请输入充值金额");
      }
      if (!this.RechargeForm.objectName) {
        return this.$message.error("请上传打款凭证");
      }
      let param = {
        rechargeAmount: this.RechargeForm.rechargeAmount,
        remittanceVoucher: this.RechargeForm.objectName,
      };

      recharge(param).then(({ code, msg }) => {
        if (code == 200) {
          this.$message.success(msg + "，请耐心等待，审核后汇款金额充值到账");
          this.getFirstList();
          this.RechargeForm.rechargeAmount = "";
          this.RechargeForm.objectName = "";
          this.RechargeForm.remittanceVoucher = [];
        } else {
          this.$message.error(msg);
        }
      });
    },
    submitPass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.ruleForm));
          params.oldPassword = encrypt(params.oldPassword);
          params.newPassword = encrypt(params.newPassword);
          params.checkPass = "";
          updatePaymentPwd(params).then(({ code, msg }) => {
            if (code == 200) {
              this.dialogPass = false;
              this.$message.success(msg);
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogPass = false;
      this.dialogMSG = false;
      this.dialogPay = false;
      this.dialogCard = false;
      this.dialogWithdrawal = false;
    },
    getYzm() {
      this.countdown = 60;
      let param = {
        mobile: this.userInfo.userName,
      };
      getSmsCode(param).then(({ code, msg }) => {
        if (code !== 200) {
          this.$message.error(msg);
        }
      });
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },

    getBlance() {
      balance().then(({ code, data }) => {
        if (code == 200) {
          this.data = data;
        }
      });
    },
    getMemberList() {
      memberList().then(({ code, rows }) => {
        if (code == 200) {
          console.log(code, rows);
          this.thisMemberList = rows.map((item) => {
            return {
              label: item.realName,
              value: item.userId,
              userName: item.userName,
            };
          });
        }
      });
    },
    getFirstList() {
      transactionList(this.padeObj1).then(({ code, rows, total }) => {
        if (code == 200) {
          let map = {
            null: "待处理",
            0: "待处理",
            1: "已完成",
            2: "已取消",
          };
          this.tableData = rows;
          this.tableData.forEach((i) => {
            i.status_cn = map[i.status];
            i.businessType_cn = this.duiying(i.businessType);
          });
          this.padeObj1.total = total;
        }
      });
    },
    duiying(val) {
      let obj = this.dict.wallet_oper_type.find((item) => item.value == val);
      return obj.label;
    },
    passUpdate() {},
  },
  watch: {
    visible(val) {
      if (val) this.$refs["passwordInputRef"].focus();
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  filters: {
    // 金额添加千分符
    formatMoneyString(moneyString) {
      if (!moneyString) {
        return "--";
      }
      // 将金额字符串转换为数字
      const amount = parseFloat(moneyString);
      // 检查是否成功转换为数字
      if (isNaN(amount)) {
        return "--";
      }
      // 使用 toLocaleString() 方法添加千分符并保留两位小数
      const formattedAmount = amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formattedAmount;
    },
  },
};
</script>

<style lang="less" scoped>
.Account {
  margin-top: 20px;
  margin-left: 30px;
  // width: 800px;
  .yell {
    color: #ff7f00;
  }
  .pagina {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .titles {
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    margin: 10px 0;
    font-weight: 600;
    padding-left: 10px;
    border-left: 6px solid #0dac65;
  }
  /deep/.el-tabs__item {
    color: rgba(0, 0, 0, 0.6);
  }
  /deep/.el-tabs__item.is-active {
    color: #0dac65;
    font-weight: 600;
  }
  .type1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    .item {
      width: 32%;
      display: flex;
      .cont {
        margin-left: 10px;
        .tit {
          color: rgba(0, 0, 0, 0.6);
          line-height: 30px;
          font-size: 14px;
        }
        .num {
          font-weight: 600;
          font-size: 22px;
        }
      }
      .butt {
        margin-left: 20px;
        margin-top: 24px;
      }
    }
    .item2 {
      box-sizing: border-box;
      padding-left: 20px;
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
    }
  }
  .shezhi {
    .item {
      width: 60%;
      height: 40px;
      font-size: 14px;
      color: #00000099;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cen {
        width: 200px;
      }
    }
  }
  .Recharge {
    display: flex;
    .tit {
      font-size: 16px;
      font-weight: 600;
      line-height: 60px;
    }
    .titNames {
      font-size: 14px;
      line-height: 60px;
    }
    .left {
      width: 45%;
      height: 100%;
      .steps {
        .step {
          padding-left: 40px;
          margin-bottom: 30px;
          position: relative;
          .step-marker {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #0dac652a;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            left: 0;
          }

          .step-content {
            margin-left: 10px;
          }

          .step-title {
            margin-bottom: 5px;
            color: #000000d9;
            font-size: 14px;
          }

          .step-description {
            color: #888;
            line-height: 24px;
          }

          &:last-child .step-connector {
            display: none;
          }

          .step-connector {
            position: absolute;
            top: 30px;
            left: 15px;
            width: 3px;
            height: calc(100%);
            background-color: #0dac652a;
            transform: translateX(-50%);
          }
        }
      }
    }
    .right {
      margin-left: 5%;
      width: 50%;
      height: 50vh;
    }
  }
  .coupons {
    padding: 10px;
    .left {
      margin-top: 20px;
    }
    .tit {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
      padding-left: 10px;
      border-left: 4px solid #0dac65;
    }
    .card {
      width: 400px;
      height: 100px;
      background-image: linear-gradient(90deg, #52d5c0 0%, #40ae96 100%);
      border-radius: 8px;
      color: #fff;
      box-sizing: border-box;
      padding-left: 100px;
      padding-top: 10px;
      font-size: 16px;
      line-height: 40px;
    }
    .invoiceList {
      .invoiceItem {
        width: 420px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        .names {
          display: inline-block;
          width: 10rem;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
        }
      }
    }
  }
  .Withdrawal {
    .tit {
      font-weight: 600;
      font-size: 16px;
      line-height: 60px;
    }
    .top {
      padding-left: 20px;
      position: relative;
      padding-top: 20px;
      .rmb {
        font-size: 24px;
        font-weight: 500;
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 999;
      }
    }
    /deep/.el-input__inner {
      border: none;
      height: 50px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
      font-size: 32px;
      text-align: left;
      padding-left: 30px;
      color: #333;
    }
    /deep/.el-input-number__decrease {
      display: none;
    }
    /deep/.el-input-number__increase {
      display: none;
    }
    .center {
      padding-left: 10px;
      border-bottom: 1px solid #eee;
      .account {
        padding: 10px;
        line-height: 30px;
        .accItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #333;
          .label {
            color: #999;
          }
        }
      }
    }
    .bottom {
      padding-left: 20px;
    }
  }
}
</style>
